<template>
  <JoszakiModal
    :open="modalOpen"
    :title="
      mate
        ? $t('newTender.header.titleWithPerson')
        : $t('newTender.header.title')
    "
    size="lg"
    :loading="loading"
    @confirm="submit"
    @cancel="close"
    @close="close"
  >
    <template #header>
      <p class="font-semibold text-base md:font-bold md:text-lg text-primary">
        <template v-if="mate">
          {{ $t("newTender.header.titleWithPerson") }}
        </template>
        <template v-else>
          {{ $t("newTender.header.title") }}
        </template>
      </p>
    </template>
    <template #body>
      <div
        class="w-full flex justify-center items-center relative z-[2] gap-2 md:gap-8"
      >
        <div v-if="mate" class="flex-shrink-0 w-10 h-10 md:w-20 md:h-20">
          <div class="rounded-full h-full w-full overflow-hidden">
            <PersonPictureSet
              class="w-10 h-10 md:w-20 md:h-20 object-cover"
              :person-id="mate.id"
            />
          </div>
        </div>
        <div class="flex-1">
          <p
            class="font-bold text-2xl mb-2 md:font-extrabold md:text-3xl text-primary-dark md:mb-4"
          >
            <template v-if="mate">
              {{ mate.name }}
            </template>
          </p>
          <p
            v-if="
              !mate && prefilled && $ab.isVariant('new-tender-2', 'variant2')
            "
            class="font-semibold text-base md:font-bold md:text-lg text-primary"
          >
            {{
              $t("newTender.header.secondaryTitleWithCityAndProf", {
                city: newTender.city?.value?.name?.toLowerCase(),
                profession:
                  newTender.profession?.value?.objective?.toLowerCase(),
              })
            }}
            <br />
            <a
              class="text-primary-dark"
              @click="newTender.modifyCityAndProfession"
              >({{ $t("newTender.modifyCityAndProfession") }})</a
            >
          </p>
          <p
            v-if="
              !mate && (!prefilled || $ab.isVariant('new-tender-2', 'variant1'))
            "
            class="font-semibold text-base md:font-bold md:text-lg text-primary"
          >
            {{ $t("newTender.header.secondaryTitle") }}
          </p>
        </div>
      </div>

      <form id="new-tender" class="grid gap-4">
        <FieldName ref="name" @next="next" />
        <FieldDescription ref="jobDescription" @next="next" />
        <template
          v-if="!prefilled || $ab.isVariant('new-tender-2', 'variant1')"
        >
          <FieldProfession ref="profession" @next="next" />
          <FieldCity ref="city" @next="next" />
        </template>
        <FieldEmail ref="email" @next="next" />
        <FieldPhone ref="phone" @next="next" />
      </form>
    </template>

    <template #footer>
      <div
        class="flex flex-col md:flex-row gap-4 md:gap-8 justify-center items-center"
      >
        <div class="flex-1">
          {{ $t("newTender.termsOfUSe.prompt") }}
          <a href="/aszf" target="_blank">{{
            $t("newTender.termsOfUSe.linkText")
          }}</a>
        </div>
        <JoszakiButton type="success" :loading="loading" @click="submit">
          {{ $t("newTender.submit") }}
        </JoszakiButton>
      </div>
    </template>
  </JoszakiModal>
</template>

<script>
import { computed } from "@nuxtjs/composition-api";
import FieldDescription from "~/components/_refactored/newTender/FieldDescription.vue";
import FieldName from "~/components/_refactored/newTender/FieldName.vue";
import FieldCity from "~/components/_refactored/newTender/FieldCity.vue";
import FieldEmail from "~/components/_refactored/newTender/FieldEmail.vue";
import FieldProfession from "~/components/_refactored/newTender/FieldProfession.vue";
import FieldPhone from "~/components/_refactored/newTender/FieldPhone.vue";

import PersonPictureSet from "~/components/PersonPictureSet.vue";
import { useNewTender } from "~/composables/tender/useNewTender";

export default {
  components: {
    PersonPictureSet,
    FieldDescription,
    FieldCity,
    FieldEmail,
    FieldName,
    FieldProfession,
    FieldPhone,
  },
  setup() {
    try {
      const newTender = useNewTender();

      const mate = computed(() => {
        return newTender.state.mate;
      });

      const modalOpen = computed(() => {
        return newTender.state.modalOpen;
      });

      const loading = computed(() => {
        return newTender.state.loading;
      });

      const prefilled = computed(() => {
        return newTender.state.prefilled;
      });

      function close(isOutsideClick = false) {
        if (!isOutsideClick) {
          newTender.close();
        }
      }

      const nextMapping = {
        name: "jobDescription",
        jobDescription: "profession",
        profession: "city",
        city: "email",
        email: "phone",
      };

      return {
        newTender,
        v$: null,
        mate,
        modalOpen,
        close,
        loading,
        prefilled,
        nextMapping,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  methods: {
    focusField() {
      const fields = [
        "name",
        "jobDescription",
        "profession",
        "city",
        "email",
        "phone",
      ];
      for (const field of fields) {
        if (this.newTender.v$.value[field]?.$error) {
          this.$refs[field].$el.scrollIntoView();
          this.$refs[field].focus();
          return;
        }
      }
    },
    next(source) {
      if (source === "phone") {
        this.submit();
        return;
      }
      if (
        this.$ab.isVariant("new-tender-2", "variant1") &&
        (source === "jobDescription" || source === "profession")
      ) {
        return;
      }
      this.$refs[this.nextMapping[source]].$el.scrollIntoView();
      this.$refs[this.nextMapping[source]].focus();
    },
    async submit() {
      this.newTender.terms.value = true;
      if (this.loading) {
        console.debug("submitJob: already loading");
        // avoid double submit
        return;
      }
      const valid = await this.newTender.v$.value.$validate();
      if (!valid) {
        console.debug("submitJob: invalid form");
        this.focusField();
        return;
      }

      try {
        const { tender } = await this.newTender.createTender(true);

        this.newTender.close();

        if (this.mate) {
          if (this.mate.isInactive) {
            // unreachable code at the moment
            this.$router.push(
              this.localePath({
                name: "requestForTenderTab",
                params: {
                  activeTab: 8,
                },
                query: {
                  ...this.$route.query,
                  ...{
                    munka: tender.id,
                    personId: this.mate.id,
                  },
                },
              })
            );
          } else {
            this.$router.push(
              this.localePath({
                name: "specifyTenderPage",
                params: {
                  tenderId: tender.id,
                  activeTab: 1,
                },
              })
            );
          }
        } else {
          this.$router.push(
            this.localePath({
              name: "specifyTenderPage",
              params: {
                tenderId: tender.id,
                activeTab: 2,
              },
            })
          );
        }
      } catch (err) {
        console.error("submitJob: error", err);
        this.$sentry.addBreadcrumb({
          category: "newTender",
          message: "submitJob",
          level: "info",
        });
        this.$sentry.captureException(err, {
          tags: {
            rtf: "newTender",
            newTender: "submitJob",
          },
        });
        this.$joszaki.alert({
          message: "Valami nem stimmel! Próbáld újra!",
          type: "error",
        });
      }
    },
  },
};
</script>
