<template>
  <div
    class="headerClass"
    :class="{
      'bg-red': switchedToMate,
    }"
    @click.stop
  >
    <b-navbar
      :close-on-click="false"
      :active.sync="navbarOpen"
      :mobile-burger="showButtons"
    >
      <template #brand>
        <b-navbar-item
          tag="NuxtLink"
          :href="$helpers.topDomain"
          class="pr-0"
          to="/"
        >
          <JoszakiLogo style="width: 144px; height: 28px" />
        </b-navbar-item>
      </template>
      <template #burger="burgerProps">
        <span v-if="showButtons" class="flex justify-end flex-1 m-1 lg:hidden">
          <JoszakiButton @click="onRequestTender({ source: 'header' })">
            Ajánlatkérés
          </JoszakiButton>
        </span>
        <a
          role="button"
          class="navbar-burger burger"
          :class="{ 'is-active': burgerProps.isOpened }"
          aria-label="menu"
          :aria-expanded="burgerProps.isOpened"
          tabindex="0"
          @click="burgerProps.toggleActive"
          @keyup="
            (e) => {
              if (e.keyCode === 13) burgerProps.toggleActive();
            }
          "
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </template>
      <template v-if="showSearch" #start>
        <div
          v-if="!$ab.isVariant('old-header-search', 'test')"
          class="flex flex-col items-center lg:!flex-row"
        >
          <div
            class="flex flex-col gap-2 items-center lg:!flex-row px-2 lg:px-0 is-hidden-desktop-only w-full lg:w-auto is-hidden-widescreen-only"
          >
            <div
              class="flex-shrink-0 h-10 w-full lg:!w-auto bg-white rounded overflow-hidden z-10 cursor-pointer flex items-center gap-2 px-4 border border-gray-300 text-sm text-primary"
              @click.stop="onSelectCity"
            >
              <IconComponent class="text-primary" icon="map-marker-alt" />
              {{ cityFilter.name }}
            </div>

            <div class="flex items-center w-full lg:w-auto">
              <input
                v-model="currentSearchInput"
                type="search"
                class="w-full 2xl:!w-64 px-4 h-10 border border-gray-300 z-10 focus-visible:outline-primary rounded-tl rounded-bl text-sm font-nunito"
                :placeholder="$t('header.search.placeholder')"
                @keypress.enter="goToSearch"
              />

              <div
                class="grid flex-shrink-0 w-10 h-10 text-white rounded-tr rounded-br cursor-pointer place-items-center bg-primary"
                @click="goToSearch"
              >
                <IconComponent class="text-sm" icon="search" />
              </div>
            </div>
          </div>
        </div>

        <!-- Old search -->
        <div v-else class="flex flex-wrap items-center">
          <b-navbar-item
            tag="div"
            class="flex-shrink-0 is-hidden-desktop-only is-hidden-widescreen-only grow basis-full md:flex-grow-0 md:basis-auto md:w-36 2xl:w-48"
          >
            <AutocompleteProfession
              :placeholder="$t('peopleHeader.profession.placeholder')"
              :value="profession"
              @select="setProfession"
            />
          </b-navbar-item>
          <b-navbar-item
            tag="div"
            class="flex-shrink-0 is-hidden-desktop-only is-hidden-widescreen-only grow basis-full md:flex-grow-0 md:basis-auto md:w-36 2xl:w-48"
          >
            <AutocompleteCity
              :placeholder="$t('peopleHeader.cities.placeholder')"
              :value="city"
              @select="setCity"
            />
          </b-navbar-item>
          <b-navbar-item
            tag="div"
            class="is-hidden-desktop-only is-hidden-widescreen-only"
          >
            <b-button type="is-primary is-fullwidth" @click="search">
              <span class="hidden 2xl:inline-block">
                {{ $t("peopleHeader.searchButton") }}
              </span>
              <IconComponent class="2xl:hidden" icon="search" />
            </b-button>
          </b-navbar-item>
        </div>

        <b-navbar-item v-if="switchedToMate">
          <b-button type="is-info" expanded @click="switchBackToAdmin">
            {{ $t("admin.functions.switchBackToAdmin") }}
          </b-button>
        </b-navbar-item>
      </template>

      <template #end>
        <JoszakiButton
          v-if="showButtons"
          class="m-1 is-hidden-touch"
          @click="onRequestTender({ source: 'header' })"
        >
          {{ $t("peopleHeader.tenderButton") }}
        </JoszakiButton>
        <NuxtLink
          v-if="showButtons"
          class="flex items-center justify-center w-12 text-gray-600 border-l border-gray-200 is-hidden-touch is-hidden-fullhd"
          tag="a"
          :to="localePath({ name: 'search' })"
        >
          <b-icon icon="search" aria-hidden="true" />
          <span class="is-sr-only">{{ $t("peopleHeader.searchButton") }}</span>
        </NuxtLink>
        <b-navbar-item
          v-if="showButtons && isJoszaki"
          v-show="favoriteMateCount > 0"
          tag="nuxt-link"
          class="!flex items-center lg:justify-center md:border-solid md:border-l md:border-gray-200"
          :to="localePath({ name: 'favorites' })"
          @click.native="onClickFavorites"
        >
          <IconComponent icon="heart" class="mr-2 text-red-500" />
          <span class="md:hidden 2xl:inline-block">
            {{ $t("peopleHeader.favorites") }}
          </span>
          ({{ favoriteMateCount }})
        </b-navbar-item>
        <b-navbar-item
          v-if="showButtons && isJoszaki"
          :to="localePath({ name: 'questionsPage' })"
          tag="nuxt-link"
          class="header-link"
          @click.native="onClickMateAnswer"
        >
          {{ $t("peopleHeader.questionsPage") }}
        </b-navbar-item>
        <b-navbar-item
          v-if="showButtons && ($mq === 'mobile' || $mq === 'tablet')"
          class="header-link"
          @click.native.prevent.stop="onRequestTender({ source: 'header' })"
        >
          {{ $t("peopleHeader.requestTender") }}
        </b-navbar-item>
        <div v-if="showButtons && isJoszaki" class="dropdown-menu-item">
          <b-navbar-item
            tag="NuxtLink"
            :to="localePath({ name: 'pricesPage' })"
            class="header-link"
          >
            {{ $t("peopleHeader.pricing") }}
          </b-navbar-item>
          <PricingLinks v-if="!navbarOpen" class="dropdown-menu-item-content" />
        </div>

        <div v-if="showButtons" class="dropdown-menu-item">
          <b-navbar-item
            tag="NuxtLink"
            :to="localePath({ name: 'professionsLandingPage' })"
            class="header-link"
            @click.native="onHeaderItemClick('professions')"
          >
            {{ $t("peopleHeader.professions") }}
          </b-navbar-item>
          <ProfessionLinks
            v-if="!navbarOpen"
            class="dropdown-menu-item-content"
          />
        </div>

        <b-navbar-item
          v-if="!me && !meLoading && showButtons"
          class="header-link"
          tag="NuxtLink"
          :to="localePath({ name: 'registrationPage' })"
          @click.native="onClickRegister"
        >
          {{ $t("peopleHeader.register") }}
        </b-navbar-item>
        <b-navbar-item
          v-if="!me && !meLoading && showButtons"
          class="header-link"
          tag="NuxtLink"
          :to="localePath({ name: 'login' })"
          @click.native="onClickLogin"
        >
          {{ $t("peopleHeader.login") }}
        </b-navbar-item>

        <b-navbar-item v-if="meLoading" class="header-link">
          <img
            v-lazy-load data-src="~/assets/img/loader.svg"
            width="50"
            height="50"
            alt="Loading"
          />
        </b-navbar-item>

        <UserMenu v-if="me && showButtons" @click="navbarOpen = false" />
      </template>
    </b-navbar>
  </div>
</template>

<script>
import { mapState } from "pinia";
import JoszakiButton from "../_refactored/common/JoszakiButton.vue";
import PricingLinks from "./header/PricingLinks.vue";
import ProfessionLinks from "./header/ProfessionLinks.vue";
import AutocompleteProfession from "@/components/_refactored/autocomplete/AutocompleteProfession.vue";
import AutocompleteCity from "@/components/_refactored/autocomplete/AutocompleteCity.vue";
import UserMenu from "@/components/global/header/UserMenu.vue";
import JoszakiLogo from "@/components/_refactored/img/JoszakiLogo.vue";
import { useRequestTender } from "~/composables/requestTender";
import { useUserStore } from "~/stores/user";
import { useAdminUserSwitcher } from "~/components/_refactored/admin";
import { useBrand } from "~/composables/useBrand";

export default {
  components: {
    ProfessionLinks,
    PricingLinks,
    UserMenu,
    JoszakiLogo,
    JoszakiButton,
    AutocompleteProfession,
    AutocompleteCity,
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { isJoszaki } = useBrand();
    return {
      userSwitcher: useAdminUserSwitcher(),
      ...useRequestTender(),
      isJoszaki,
    };
  },
  data() {
    return {
      profession: undefined,
      navbarOpen: false,
      isActive: true,
      city: undefined,

      currentSearchInput: "",
    };
  },
  computed: {
    ...mapState(useAdminUserSwitcher, {
      switchedToMate: "switchedToMate",
    }),
    ...mapState(useUserStore, {
      me: "me",
      meLoading: "meLoading",
    }),
    favoriteMateCount() {
      return this.$store.getters["localStorage/likedPersonCount"];
    },
    cityFilter() {
      return this.$store.getters["search/city"];
    },
    cityFilterLoaded() {
      return this.$store.getters["search/loaded"];
    },
    registrationLink() {
      return this.$helpers.addToUrl(
        this.$helpers.topDomain,
        this.professionSeoName,
        "regisztracio"
      );
    },
  },
  watch: {
    $route() {
      this.navbarOpen = false;
    },
  },
  beforeMount() {
    this.$nuxt.$on("listpage-click", this.closeNavbar);
    this.$store.dispatch("localStorage/initiateModule");
  },
  beforeDestroy() {
    this.$nuxt.$off("listpage-click", this.closeNavbar);
  },
  methods: {
    onRequestTender({ source }) {
      this.requestTender({
        profession: this.$store.getters["listPage/profession"],
        city: this.$store.getters["listPage/city"],
        source,
      });
    },
    onHeaderItemClick(headerItem) {
      this.$trackers.trackEvent({
        title: "Header item click",
        data: {
          headerItem,
        },
      });
    },
    onSelectCity() {
      this.navbarOpen = false;
      this.$emit("selectCity");
    },
    setCity(arg) {
      this.city = arg;
    },
    setProfession(arg) {
      this.profession = arg;
    },
    closeNavbar() {
      this.navbarOpen = false;
    },
    async switchBackToAdmin() {
      await this.userSwitcher.switchBackToAdmin();
    },
    search() {
      if (!this.profession) {
        this.$buefy.toast.open({
          message: this.$t("peopleHeader.profession.requiredMsg"),
          type: "is-danger",
        });
        return;
      }
      if (!this.city) {
        this.$buefy.toast.open({
          message: this.$t("peopleHeader.cities.requiredMsg"),
          type: "is-danger",
        });
        return;
      }

      this.$trackers.trackEvent({
        title: "Header click list search",
        data: {
          professionSeoName: this.profession.seoName,
          citySeoName: this.city.seoName,
        },
      });

      if (this.city.parentCity) {
        this.$router.push(
          this.localePath({
            name: "professionalsParentCity",
            params: {
              profession: this.profession.seoName,
              parentCity: this.city.parentCity.seoName,
              city: this.city.seoName,
            },
          })
        );
        return;
      }
      this.$router.push(
        this.localePath({
          name: "professionals",
          params: {
            profession: this.profession.seoName,
            city: this.city.seoName,
          },
        })
      );
    },
    goToSearch(event) {
      try {
        event.target.blur();
      } catch (error) {
        console.error("Cant defocus input field");
      }
      this.navbarOpen = false;
      this.$trackers.trackEvent({
        title: "Clicked on Full Text Search",
        data: {
          citySeoName: this.cityFilter.seoName,
          search: this.currentSearchInput,
          source: "header",
        },
      });
      this.$store.dispatch("search/setSearchQuery", this.currentSearchInput);
      this.$router.push(
        this.localePath({
          name: "search",
          query: { search: this.currentSearchInput },
        })
      );
    },
    onClickMateAnswer() {
      this.$trackers.trackEvent({
        title: "Header click mate answer",
      });
    },
    onClickFavorites() {
      this.$trackers.trackEvent({
        title: "Header click favorites",
      });
    },
    onClickBlog() {
      this.$trackers.trackEvent({
        title: "Header click blog",
      });
    },
    onClickRegister() {
      console.info("reg");
      this.$trackers.trackEvent({
        title: "Header click register",
      });
    },
    onClickLogin() {
      this.navbarOpen = false;
      this.$trackers.trackEvent({
        title: "Header click login",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use "styles/_all-members" as global;

.headerClass {
  max-height: global.$header-height;
  position: sticky;
  top: 0px;
  z-index: 1000;
}
.navbar {
  border-bottom: 3px solid global.$primary;
}

.navbar-burger,
.navbar-item {
  @include global.tablet {
    min-height: 3.5rem;
  }
}

.header-link {
  border-left: 1px solid #eee;
}

.bg-red .navbar {
  background-color: #ff0000ba !important;
}

.navbar-start {
  .navbar-item {
    @include global.widescreen {
      padding-left: 0;
    }
  }

  .autocomplete {
    &::v-deep {
      .input {
        @include global.widescreen {
          max-width: 11.5rem;
        }
      }
    }
  }
}

.dropdown-menu-item {
  position: relative;
  display: block;

  .dropdown-menu-item-content {
    position: fixed;
    right: 0;
    z-index: 1;
  }

  &:not(:hover) .dropdown-menu-item-content {
    display: none !important;
  }
}
</style>
