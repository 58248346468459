var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed h-[22.5rem] bg-[#202A35] top-14 left-0 z-[1000] flex justify-center w-full"},[_c('div',{staticClass:"container columns px-5 py-8"},[_c('div',{staticClass:"column is-three-fifths text-white"},[_c('div',{staticClass:"border-white border-solid border-0 border-b pb-1"},[_vm._v(" "+_vm._s(_vm.$t("header.pricing.articles"))+" ")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-one-quarter p-5"},[_c('NuxtLink',{staticClass:"pricing-link",attrs:{"to":_vm.localePath({
                name: 'prices',
                params: { professionTask: 'tisztasagi-festes' },
              })}},[_vm._v(" Tisztasági festés ")]),_c('NuxtLink',{staticClass:"pricing-link",attrs:{"to":_vm.localePath({
                name: 'prices',
                params: { professionTask: 'szobafestes' },
              })}},[_vm._v(" Szobafestés ")]),_c('NuxtLink',{staticClass:"pricing-link",attrs:{"to":_vm.localePath({
                name: 'prices',
                params: { professionTask: 'gletteles' },
              })}},[_vm._v(" Glettelés ")]),_c('NuxtLink',{staticClass:"pricing-link",attrs:{"to":_vm.localePath({
                name: 'prices',
                params: { professionTask: 'tapetazas' },
              })}},[_vm._v(" Tapétázás ")]),_c('NuxtLink',{staticClass:"pricing-link",attrs:{"to":_vm.localePath({
                name: 'prices',
                params: { professionTask: 'vakolas' },
              })}},[_vm._v(" Vakolás ")]),_c('NuxtLink',{staticClass:"pricing-link",attrs:{"to":_vm.localePath({
                name: 'prices',
                params: { professionTask: 'mazolas' },
              })}},[_vm._v(" Mázolás ")]),_c('NuxtLink',{staticClass:"pricing-link",attrs:{"to":_vm.localePath({
                name: 'prices',
                params: { professionTask: 'gipszkarton-szereles' },
              })}},[_vm._v(" Gipszkartonozás ")]),_c('NuxtLink',{staticClass:"pricing-link",attrs:{"to":_vm.localePath({
                name: 'prices',
                params: { professionTask: 'gipszkarton-valaszfal-epites' },
              })}},[_vm._v(" Gipszkarton fal ")])],1),_c('div',{staticClass:"column is-one-quarter p-5"},[_c('NuxtLink',{staticClass:"pricing-link",attrs:{"to":_vm.localePath({
                name: 'prices',
                params: { professionTask: 'almennyezet-keszites' },
              })}},[_vm._v(" Gipszkarton álmennyezet ")]),_c('NuxtLink',{staticClass:"pricing-link",attrs:{"to":_vm.localePath({
                name: 'prices',
                params: { professionTask: 'villanyszereles' },
              })}},[_vm._v(" Villanyszerelés ")])],1)]),_c('a',{staticClass:"block text-[#f0ad4e] hover:text-[#f0ad4e] font-bold",attrs:{"href":`${_vm.$helpers.topDomain}/ar`}},[_vm._v(" "+_vm._s(_vm.$t("header.pricing.show-all"))+" "),_c('b-icon',{attrs:{"icon":"chevron-right"}})],1)]),_c('div',{staticClass:"column is-two-fifths"},[_c('div',{staticClass:"border-white border-solid border-0 border-b pb-1 text-white"},[_vm._v(" "+_vm._s(_vm.$t("header.pricing.visit-again"))+" ")]),_c('div',{staticClass:"text-white text-2xl text-center font-bold"},[_vm._v(" "+_vm._s(_vm.$t("header.pricing.working-on-more"))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }