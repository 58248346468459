<template>
  <div
    class="fixed h-[22.5rem] bg-[#202A35] top-14 right-0 z-[1000] flex justify-end md:w-8/12 xl:w-1/2"
  >
    <div class="px-5 py-8 w-full">
      <div class="border-white border-solid border-0 border-b pb-1 text-white">
        {{ $t("professionLinks.popularProfessions") }}
      </div>
      <div class="columns">
        <div
          v-for="(professionArray, index) in professionsArrays"
          :key="index"
          class="column is-half p-3"
        >
          <div
            v-for="profession in professionArray"
            :key="`profession-${profession.id}`"
            class="mb-2"
          >
            <NuxtLink
              class="text-white hover:text-white mr-auto profession-link"
              :to="
                localePath({
                  name: 'professionPage',
                  params: { profession: profession.seoName },
                })
              "
              @click="onProfessionLinkClick(profession)"
            >
              {{ profession.name }}
            </NuxtLink>
          </div>
        </div>
      </div>
      <NuxtLink
        class="block text-[#f0ad4e] hover:text-[#f0ad4e] font-bold pb-5"
        :to="localePath({ name: 'professionsLandingPage' })"
        @click="onProfessionLinkClick('others')"
      >
        {{ $t("professionLinks.others") }} <b-icon icon="chevron-right" />
      </NuxtLink>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    popularProfessions() {
      return this.$store.getters["data/popularProfessions"];
    },
    professionsArrays() {
      return [
        this.popularProfessions.slice(0, 7),
        this.popularProfessions.slice(7),
      ];
    },
  },
  methods: {
    onProfessionLinkClick(profession) {
      this.$trackers.trackEvent({
        title: "Header click on Profession",
        data: {
          professionSeoName: profession.seoName,
        },
      });
    },
  },
};
</script>

<style>
.profession-link {
  text-transform: capitalize;
  @apply text-white hover:text-white font-bold block;
}
</style>
